import { paymentApi } from '~/shared/api'
import {
  Events,
  PaymentSystem,
  ScreenName,
  UpsellProduct,
} from '~/shared/constants'
import {
  getPurchaseFailedEventParams,
  getPurchaseStartedEventParams,
  getPurchaseSuccessEventParams,
} from '~/shared/helpers/purchaseEventParams'
import { logSuccessfulPayment } from '~/shared/helpers/stripePaymentLogger'
import { eventLogger } from '~/shared/services'
import { IAppState, TAppDispatchThunk } from '~/shared/store/types'

import { startFetching, stopFetching } from '../../common/actions'
import {
  getUserStatusAction,
  savePlanAdditionsAction,
} from '../../user/actions'
import { selectUUID, selectUserPaymentSystem } from '../../user/selectors'
import {
  selectCurrency,
  selectInAppId,
  selectPaymentMethod,
  selectProductId,
  selectSubscription,
  selectSubscriptionTrialLookupKey,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
} from '../selectors'

const MODULE_NAME = 'UPSELL'

// actions types
export const MAKE_UPSELL = `${MODULE_NAME}/MAKE_UPSELL`

export const makeUpsellAction =
  (screenName: ScreenName): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)
    const productId = selectProductId(state)
    const trialPriceId = selectSubscriptionTrialLookupKey(state)
    const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
    const trialCurrentPrice = selectSubscriptionTrialPeriodPrice(state)
    const currency = selectCurrency(state)
    const selectedInAppId = selectInAppId(state)
    const selectedSubscription = selectSubscription(state)
    const paymentMethod = selectPaymentMethod(state)
    const paymentSystem = selectUserPaymentSystem(state)

    dispatch(startFetching(MAKE_UPSELL))

    const commonPurchaseStartedParams = getPurchaseStartedEventParams(state)
    const commonPurchaseSuccessParams = getPurchaseSuccessEventParams(state)
    const commonPurchaseFailedParams = getPurchaseFailedEventParams(state)

    eventLogger.logPurchaseStarted({
      ...commonPurchaseStartedParams,
      paymentMethod,
      event: Events.PURCHASE_STARTED,
      screenName,
      priceDetails: { price: trialCurrentPrice, currency },
      productId: trialPriceId,
    })

    const dto = {
      uuid,
      productId: productId || UpsellProduct.BOTH_RECURRENT,
      planId: Number(selectedInAppId),
    }

    const response =
      paymentSystem === PaymentSystem.PRIMER
        ? await paymentApi.makePrimerUpsell(dto)
        : await paymentApi.makeUpsell(dto)

    if (response.success && response.data && selectedSubscription) {
      logSuccessfulPayment({
        ...commonPurchaseSuccessParams,
        isUpsell: true,
        paymentSystem,
        price: trialCurrentPrice,
        trialPeriodDays,
        subscriptionId: response.data.subscription.subscription_id,
        paymentMethod,
        screenName,
        productId: response.data.subscription.product_id,
      })

      dispatch(savePlanAdditionsAction())
      dispatch(getUserStatusAction(uuid))
      dispatch(stopFetching(MAKE_UPSELL))
      return
    }

    eventLogger.logPurchaseFailed({
      ...commonPurchaseFailedParams,
      paymentMethod,
      paymentSystem,
      error: { type: response.data, declineCode: response.data.decline_code },
      screenName,
      priceDetails: { price: trialCurrentPrice, currency },
      event: Events.PURCHASE_FAILED,
      productId: trialPriceId,
    })

    dispatch(getUserStatusAction(uuid))
    dispatch(stopFetching(MAKE_UPSELL))
  }

export const createInAppAction =
  (screenName: ScreenName): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)
    const trialPriceId = selectSubscriptionTrialLookupKey(state)
    const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
    const trialCurrentPrice = selectSubscriptionTrialPeriodPrice(state)
    const currency = selectCurrency(state)
    const selectedInAppId = selectInAppId(state)
    const selectedSubscription = selectSubscription(state)
    const paymentMethod = selectPaymentMethod(state)
    const paymentSystem = selectUserPaymentSystem(state)

    dispatch(startFetching(MAKE_UPSELL))

    const commonPurchaseStartedParams = getPurchaseStartedEventParams(state)
    const commonPurchaseSuccessParams = getPurchaseSuccessEventParams(state)
    const commonPurchaseFailedParams = getPurchaseFailedEventParams(state)

    eventLogger.logPurchaseStarted({
      ...commonPurchaseStartedParams,
      paymentMethod,
      paymentSystem,
      event: Events.INAPP_PURCHASE_STARTED,
      screenName,
      priceDetails: { price: trialCurrentPrice, currency },
      productId: trialPriceId,
    })

    const response = await paymentApi.createInAppPurchase({
      uuid,
      planId: Number(selectedInAppId),
      productName: selectedSubscription?.product,
    })

    if (response.success && response.data && selectedSubscription) {
      logSuccessfulPayment({
        ...commonPurchaseSuccessParams,
        isInApp: true,
        eventName: Events.INAPP_PURCHASE_COMPLETED,
        price: trialCurrentPrice,
        trialPeriodDays,
        subscriptionId: response.data.inapp.payment_id,
        paymentMethod,
        paymentSystem,
        screenName,
        productId: `${response.data.inapp.id};${response.data.inapp.payment_id};0`,
      })

      dispatch(savePlanAdditionsAction())
      dispatch(stopFetching(MAKE_UPSELL))
      return
    }

    eventLogger.logPurchaseFailed({
      ...commonPurchaseFailedParams,
      paymentMethod,
      paymentSystem,
      error: { type: response.data, declineCode: response.data.decline_code },
      screenName,
      priceDetails: { price: trialCurrentPrice, currency },
      event: Events.INAPP_PURCHASE_FAILED,
      productId: trialPriceId,
    })

    dispatch(stopFetching(MAKE_UPSELL))
  }
