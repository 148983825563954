import { combineReducers } from 'redux'

import { commonReducer } from './common'
import { loginReducer } from './login'
import { paymentReducer } from './payment'
import { IAppState } from './types'
import { userReducer } from './user'

const rootReducer = combineReducers<IAppState>({
  common: commonReducer,
  user: userReducer,
  payment: paymentReducer,
  login: loginReducer,
})

// eslint-disable-next-line import/no-default-export
export default rootReducer
