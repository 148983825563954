import { TValueOf } from '~/shared/types'

const CURRENT_ENVIRONMENT = {
  LOCAL: 'local',
  DEV: 'dev',
  PROD: 'prod',
  STAGE: 'stage',
} as const

const CURRENT_META_ENV = 'current-env'

export const getCurrentEnvironment = () => {
  const currentEnv = (
    document.querySelector(`meta[name='${CURRENT_META_ENV}'`) as HTMLMetaElement
  ).content as TValueOf<typeof CURRENT_ENVIRONMENT>

  return {
    isLocalEnvironment: currentEnv === CURRENT_ENVIRONMENT.LOCAL,
    isDevEnvironment: currentEnv === CURRENT_ENVIRONMENT.DEV,
    isStageEnvironment: currentEnv === CURRENT_ENVIRONMENT.STAGE,
    isProdEnvironment: currentEnv === CURRENT_ENVIRONMENT.PROD,
  }
}
