import {
  Currency,
  PaymentMethod,
  PlanAddition,
  TimeInterval,
} from '~/shared/constants'
import { roundToNearestFive } from '~/shared/helpers/numberHelper'
import { ISubscription } from '~/shared/types'

import { IAppState } from '../types'

export const selectInAppId = (state: IAppState): string =>
  state.payment.selectedSubscription?.inAppId || ''
export const selectSubscriptionId = (state: IAppState): string =>
  state.payment.selectedSubscription?.id || ''
export const selectSubscriptionPlanId = (state: IAppState): number =>
  state.payment.selectedSubscription?.planId || 0
export const selectSubscriptionLookupKey = (state: IAppState): string =>
  state.payment.selectedSubscription?.lookupKey || ''
export const selectSubscriptionTrialLookupKey = (state: IAppState): string =>
  state.payment.selectedSubscription?.trialLookupKey || ''
export const selectSubscriptionPeriodQuantity = (state: IAppState): number =>
  state.payment.selectedSubscription?.mainPrices.periodQuantity || 0
export const selectSubscriptionPeriodName = (
  state: IAppState,
): TimeInterval | null =>
  state.payment.selectedSubscription?.mainPrices.periodName || null
export const selectSubscription = (state: IAppState): ISubscription | null =>
  state.payment.selectedSubscription || null
export const selectTrialSubscriptionFullPriceBeforeDiscount = (
  state: IAppState,
): number =>
  state.payment.selectedSubscription?.trialPrices.oldPrices.beforeCustomDiscount
    ?.fullPrice || 0
export const selectSubscriptionFullPrice = (state: IAppState): number =>
  state.payment.selectedSubscription?.mainPrices.fullPrice || 0
export const selectSubscriptionTrialPeriodPrice = (state: IAppState): number =>
  state.payment.selectedSubscription?.trialPrices.fullPrice || 0
export const selectSubscriptionTrialPeriodDays = (state: IAppState): number =>
  state.payment.selectedSubscription?.trialPrices.durationDays || 0
export const selectCurrency = (state: IAppState): string =>
  state.payment.selectedSubscription?.currency.toLowerCase() || Currency.USD
export const selectProductId = (state: IAppState): string =>
  state.payment.selectedSubscription?.product || ''
export const select3DSecureIframeUrl = (state: IAppState): string =>
  state.payment.threeDSecureIframeURL
export const selectPaymentClientSecret = (state: IAppState): string =>
  state.payment.paymentClientSecret
export const selectTrialPeriodDays = (state: IAppState): number =>
  state.payment.trialPeriodDays
export const selectPlanAdditions = (state: IAppState): PlanAddition[] =>
  state.payment.planAdditions
export const selectCreatedSubscriptionId = (state: IAppState): string =>
  state.payment.subscriptionId
export const selectSubscriptionIntroDifPercents = (state: IAppState) =>
  roundToNearestFive(
    state.payment.selectedSubscription?.introDiff?.discountPercentage || 0,
  )
export const selectSubscriptionPaywallDiscountPercent = (state: IAppState) =>
  state.payment.selectedSubscription?.paywallDiscountPercent || 0
export const selectSubscriptionIntroDifAmount = (state: IAppState) =>
  state.payment.selectedSubscription?.introDiff?.discountAmount || 0

export const selectOneClickPaymentMethod = (state: IAppState) =>
  state.payment.oneClickPaymentMethod || null
export const selectStripePaymentRequest = (state: IAppState) =>
  state.payment.stripePaymentRequest || null
export const selectIsCheckoutModalShown = (state: IAppState): boolean =>
  state.payment.isCheckoutModalShown
export const selectPaymentMethod = (state: IAppState): PaymentMethod =>
  state.payment.paymentMethod
export const selectIsFirstPaymentRetryPassed = (state: IAppState): boolean =>
  state.payment.isFirstPaymentRetryPassed
export const selectPrimerClientSessionToken = (state: IAppState): string =>
  state.payment.primerClientSessionToken
