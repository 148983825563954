import { AnyAction } from 'redux'

import { INITIAL_LANGUAGE, ScreenName } from '~/shared/constants'

import { ICommonState } from '../types'
import {
  RESET_ERROR,
  SET_ANSWERS,
  SET_ANSWERS_FROM_BACKEND,
  SET_APP_CONFIG,
  SET_DYNAMIC_DISCOUNT,
  SET_ERROR,
  SET_HAS_HEADER_SKIP_BUTTON,
  SET_HEADER_SKIP_BUTTON_CALLBACK,
  SET_IS_CONTEXT_SHOWN,
  SET_LANGUAGE,
  SET_PAYMENT_CONFIG,
  SET_SCREEN_NAME,
  SET_SUBSCRIPTION_LIST,
  SET_VARIANT,
  START_FETCHING,
  STOP_FETCHING,
  UPDATE_ANSWERS,
} from './actions'

const initState: ICommonState = {
  isFetching: false,
  actionList: [],
  error: null,
  currentVariant: null,
  subscriptions: [],
  appConfig: null,
  paymentConfig: null,
  answers: null,
  screenName: ScreenName.ONBOARDING,
  language: INITIAL_LANGUAGE,
  header: {
    skip: {
      hasSkipButton: false,
    },
  },
  isPrimerRetryProcessing: false,
  dynamicDiscount: null,
  IsInContextEnabled: false,
}

export function commonReducer(
  state: ICommonState = initState,
  { type, payload = null }: AnyAction,
): ICommonState {
  switch (type) {
    case START_FETCHING: {
      return {
        ...state,
        isFetching: true,
        actionList: state.actionList.concat(payload),
      }
    }
    case STOP_FETCHING: {
      return {
        ...state,
        isFetching: !!payload.length,
        actionList: payload,
      }
    }
    case SET_ERROR: {
      return {
        ...state,
        error: payload,
      }
    }
    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      }
    }
    case SET_VARIANT: {
      return {
        ...state,
        currentVariant: payload,
      }
    }
    case SET_SUBSCRIPTION_LIST: {
      return {
        ...state,
        subscriptions: payload,
      }
    }
    case SET_APP_CONFIG: {
      return {
        ...state,
        appConfig: payload,
      }
    }
    case SET_PAYMENT_CONFIG: {
      return {
        ...state,
        paymentConfig: payload,
      }
    }
    case SET_ANSWERS: {
      return {
        ...state,
        answers: { ...state.answers, ...payload },
      }
    }
    case UPDATE_ANSWERS: {
      return {
        ...state,
        answers: { ...state.answers, ...payload },
      }
    }
    case SET_ANSWERS_FROM_BACKEND: {
      return {
        ...state,
        answers: payload,
      }
    }
    case SET_SCREEN_NAME: {
      return {
        ...state,
        screenName: payload,
      }
    }
    case SET_LANGUAGE: {
      return {
        ...state,
        language: payload,
      }
    }
    case SET_HAS_HEADER_SKIP_BUTTON: {
      return {
        ...state,
        header: {
          ...state.header,
          skip: {
            ...state.header.skip,
            hasSkipButton: payload,
          },
        },
      }
    }
    case SET_HEADER_SKIP_BUTTON_CALLBACK: {
      return {
        ...state,
        header: {
          ...state.header,
          skip: {
            ...state.header.skip,
            onSkip: payload,
          },
        },
      }
    }
    case SET_DYNAMIC_DISCOUNT: {
      return {
        ...state,
        dynamicDiscount: payload,
      }
    }
    case SET_IS_CONTEXT_SHOWN: {
      return {
        ...state,
        IsInContextEnabled: payload,
      }
    }
    default:
      return state
  }
}
