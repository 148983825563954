import { lazy } from 'react'

export const RadioOptionsView = lazy(
  () => import('./radioOptionsView/RadioOptionsView'),
)
export const KitRadioOptionsView = lazy(
  () => import('./kitRadioOptionsView/KitRadioOptionsView'),
)
export const KitCheckboxOptionsView = lazy(
  () => import('./kitCheckboxOptionsView/KitCheckboxOptionsView'),
)
export const CheckboxOptionsView = lazy(
  () => import('./checkboxOptionsView/CheckboxOptionsView'),
)
export const AnimatedSequenceView = lazy(
  () => import('./animatedSequenceView/AnimatedSequenceView'),
)
