import { TOptionsBase } from 'i18next'

import { Language } from '~/shared/constants'

type TFormatFunc<T = number | string, K = Intl.NumberFormatOptions> = (
  value: T,
  lng?: string,
  options?: K & TOptionsBase,
) => string

class I18nFormatters {
  constructor() {
    this.number = this.number.bind(this)
    this.currency = this.currency.bind(this)
    this.percent = this.percent.bind(this)
    this.datetime = this.datetime.bind(this)
    this.qualifiedLngFor = this.qualifiedLngFor.bind(this)
    this.getLang = this.getLang.bind(this)
  }

  public number: TFormatFunc = (value, lng, options) => {
    const lang = this.getLang(lng)
    const currentValue = typeof value === 'string' ? parseFloat(value) : value
    let formattedValue = new Intl.NumberFormat(lang, options).format(
      currentValue,
    )

    if (lang === Language.ES) {
      formattedValue = formattedValue.replace(/\./g, ' ')
    }

    return formattedValue
  }

  public currency: TFormatFunc = (value, lng, options) => {
    const currentValue = this.number(value, lng, {
      style: 'currency',
      currencyDisplay: 'narrowSymbol',
      ...options,
    })

    return lng === Language.EN ? currentValue.replace(/\s/g, '') : currentValue
  }

  public datetime: TFormatFunc<
    number | string | Date,
    Intl.DateTimeFormatOptions
  > = (value, lng, options) => {
    let dateValue: Date

    if (value instanceof Date) dateValue = value
    else dateValue = new Date(value)

    const dateOptions: Intl.DateTimeFormatOptions = {
      dateStyle: 'long',
      ...options,
    }

    if (lng === Language.ES) dateOptions.timeZone = 'Europe/Madrid'

    return new Intl.DateTimeFormat(this.getLang(lng), {
      ...dateOptions,
    }).format(dateValue)
  }

  public percent: TFormatFunc = (value, lng, options) => {
    let percentValue = value
    const commonOptions: Intl.NumberFormatOptions = {
      style: 'percent',
      maximumFractionDigits: 0,
      ...options,
    }

    if (typeof percentValue === 'string') {
      if (percentValue.includes('-')) {
        const parts = percentValue
          .split('-')
          .map((part) => parseFloat(part.trim()))

        percentValue = `${parts
          .map((part) => this.number(part, lng))
          .join('-')}%`

        if ([Language.ES, Language.DE, Language.FR].includes(lng as Language)) {
          percentValue = percentValue.replace('%', ' %')
        }
      } else {
        percentValue = parseFloat(percentValue)
        if (percentValue > 1) percentValue /= 100
        percentValue = this.number(percentValue, lng, commonOptions)
      }
    }

    if (typeof percentValue === 'number') {
      if (percentValue >= 1) percentValue /= 100
      percentValue = this.number(percentValue, lng, commonOptions)
    }

    return percentValue
  }

  private getLang(lng?: string) {
    return lng ? this.qualifiedLngFor(lng) : Language.EN
  }

  private qualifiedLngFor(lng: string) {
    switch (lng) {
      case Language.EN:
        return 'en-US'
      case Language.PT:
        return 'pt-BR'
      default:
        return lng
    }
  }
}

export const i18nFormatters = new I18nFormatters()
