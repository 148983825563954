export const parseQueryParams = (
  searchString: string,
): Record<string, string> =>
  searchString
    .replace(/^\?/, '')
    .split('&')
    .reduce((acc, cur) => {
      const [key, value] = cur.split('=')
      return { ...acc, [key]: decodeURIComponent(value) }
    }, {})
