import { AppWithInAppBrowser, PlatformOS } from '~/shared/constants'

export const isWebView = () => {
  if (!navigator.userAgent) return false

  const ua = navigator.userAgent.toLowerCase()

  const webViewKeywords = [
    'instagram', // Instagram webview
    'fban', // Facebook
    'fbav', // Facebook
    'twitter', // Twitter
    'line', // LINE app
    'webview', // Android
    'wv', // Android
    'tiktok', // TikTok
    'whatsapp', // WhatsApp
    'messenger', // Facebook Messenger
    'snapchat', // Snapchat
    'telegram', // Telegram
  ]

  return webViewKeywords.some((keyword) => ua.includes(keyword))
}

export function getOperatingSystemVersion(): string {
  const { userAgent } = window.navigator

  const [operatingSystemVersion = PlatformOS.UNKNOWN] =
    /[^()]+(?=\))/.exec(userAgent) || []

  return operatingSystemVersion
}

export function getMobileOperatingSystem(): PlatformOS {
  const { userAgent } = window.navigator

  if (/android/i.test(userAgent)) {
    return PlatformOS.ANDROID
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return PlatformOS.IOS
  }

  return PlatformOS.UNKNOWN
}

export function getIsMobileDevice(): boolean {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  )
}

export function getAppNameFromInAppBrowser(): AppWithInAppBrowser | null {
  const { userAgent } = window.navigator

  if (userAgent.includes('FBAN') || userAgent.includes('FBAV')) {
    return AppWithInAppBrowser.FACEBOOK
  }

  if (userAgent.includes('Instagram')) return AppWithInAppBrowser.INSTAGRAM

  return null
}
