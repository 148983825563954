import { History, createBrowserHistory } from 'history'

import { ILocationsState } from '~/shared/types'

class AppHistory {
  private history: History

  constructor() {
    this.history = createBrowserHistory()
  }

  public goTo(location: string | ILocationsState): void {
    this.history.push(location)
  }

  public goBack(): void {
    this.history.back()
  }

  public replace(location: string | ILocationsState): void {
    this.history.replace(location)
  }

  public get(): History {
    return this.history
  }
}

export const appHistory = new AppHistory()
